<template>
  <div>
    <div class="hidden-xs-only rightfloat" v-if="$i18n.locale == 'en'">
      <div class="righticon" @click="tiaozhuan('https://api.whatsapp.com/send?phone=+8613783715837&text=Hello')">
        <div class="citem" :style="{backgroundImage: 'url(' + iconlist.whatsapp + ')'}">
          <div class="rcontent">
            <div class="rtitle">Whatsapp</div>
            <div class="rtitle2">+86 13783715837</div>
          </div>
        </div>
      </div>
      <div class="righticon email" @click="tiaozhuan('mailto:sales@smartgen.cn')">
        <div class="citem" :style="{backgroundImage: 'url(' + iconlist.email + ')'}">
          <div class="rcontent">
            <div class="rtitle">Email</div>
            <div class="rtitle2">sales@smartgen.cn</div>
          </div>
        </div>
      </div>
      <div class="righticon tel" @click="tiaozhuan('tel:+8637167985313')">
        <div class="citem" :style="{backgroundImage: 'url(' + iconlist.tel + ')'}">
          <div class="rcontent">
            <div class="rtitle">Tel</div>
            <div class="rtitle2">+86-371-67981000</div>
          </div>
        </div>
      </div>
      <div class="ding fk" @click="liuyanopen()">
        <div class="dicon" :style="{backgroundImage: 'url(' + iconlist.fk + ')'}"></div>
      </div>
      <div class="ding" @click="top">
        <div class="dicon" :style="{backgroundImage: 'url(' + iconlist.top + ')'}"></div>
      </div>
    </div>
    <div class="hidden-xs-only nav">
      <!-- <div style="position:absolute;top:5px;right:20px;" :style="{'display':$i18n.locale == 'en' ? 'none' : ''}">400-0318-908</div> -->
      <div class="content">
        <div class="logo" @click="nav('index')" >
          <div style="height:40px;">
            <el-image style="height:40px;" :src="require('@/assets/logo'+($i18n.locale == 'en' || $i18n.locale == 'pt' || $i18n.locale == 'es' ? '_en' : '')+'.png')"></el-image>
          </div>
        </div>
        <div class="menu" v-if="search">
          <div style="margin-top: 15px;width:700px">
            <el-input :placeholder="$t('layout.searchplaceholder')" v-model="input2" @keyup.enter.native="searchpage">
              <el-button slot="append" style="background: #0052D9;color:#fff" @click="searchpage"> {{$t('layout.search')}}</el-button>
            </el-input>
          </div>
        </div>
        <div class="menu" v-if="!search">
          <ul class="menulist">
            <li class="menuitem" @click="nav('index')">{{$t('layout.home')}}</li>
            <li class="menuitem" @click="nav('energy')" v-if="$i18n.locale == 'zh'">新能源</li>
            <li class="menuitem activit" @mouseleave="mouseleave()" @click="nav('productinfo')">{{$t('layout.productcenter')}}
              <div class="twomenu">
                <div>
                  <ul class="twomenulist">
                    <li class="towmenuitem" v-for="(item,index) in chanpin" :key="index" @mouseover.stop="mouseover(item,index,item.cat_id)">
                      <span :style="{'color': cindex == index ?  '#0052D9' : ''}" @click.stop="nav('productinfo?class_id='+item.cat_id)">{{item.cat_name}}</span>
                    </li>
                  </ul>
                  <div class="twonav" v-if="twonavshow">
                    <div class="twocontent">
                      <div class="xilieitem" v-for="(items,indexs) in clist" :key="indexs" @click.stop="nav('productinfo?class_id='+class_id+'&id='+items.cat_id)">
                        <div class="xilieimage">
                          <el-image style="width:183px;" :src="items.image"></el-image>
                        </div>
                        <div class="biaoti">{{items.cat_name}}</div>
                      </div>
                    </div>
                    <div class="page" @click.stop="nav('productinfo?class_id='+class_id)">
                      {{$t('layout.Viewall')}}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="menuitem activit" @click="nav('schemeinfo')">{{$t('layout.solution')}}
              <div class="twomenu">
                <div style="width:80%;margin:0 auto">
                  <ul class="twomenulist">
                    <li class="towmenuitem" v-for="(item,index) in fangan" :key="index" @click.stop="nav('schemeinfo?class_id='+item.id)">{{item.class_name}}</li>
                    <li class="towmenuitem" @click.stop="nav('applicationcase')">{{$t('layout.application')}}</li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="menuitem" @click="nav('datadownload')" v-if="$i18n.locale == 'en'">{{$t('layout.download')}}</li>
            <!-- <li class="menuitem" @click="nav('applicationcase')">{{$t('layout.application')}}</li> -->
            <li class="menuitem activit" @click="nav('technical')">{{$t('layout.technical')}}
              <div class="twomenu">
                <div>
                  <ul class="twomenulist">
                    <li class="towmenuitem" @click.stop="nav('datadownload')" v-if="$i18n.locale != 'en'">{{$t('layout.download')}}</li>
                    <li class="towmenuitem" @click.stop="nav('productcatalogue')">{{$t('layout.model')}}</li>
                    <li class="towmenuitem" @click.stop="nav('ecucode')">{{$t('layout.ECUfaultcode')}}</li>
                    <li class="towmenuitem" @click.stop="nav('faqs')">{{$t('layout.FAQ')}}</li>
                    <li class="towmenuitem" @click.stop="nav('feedback')" v-if="$i18n.locale != 'en'">{{$t('layout.Productfeedback')}}</li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="menuitem activit" @click="nav('aboutus')">{{$t('layout.about')}}
              <div class="twomenu">
                <div>
                  <ul class="twomenulist">
                    <li class="towmenuitem" @click.stop="nav('news')">{{$t('layout.news')}}</li>
                    <li class="towmenuitem" @click.stop="nav('honor')">{{$t('layout.honor')}}</li>
                    <li class="towmenuitem" @click.stop="nav('trademark')">{{$t('layout.logo')}}</li>
                    <li class="towmenuitem" @click.stop="nav('hr')" v-if="$i18n.locale == 'zh'">{{$t('layout.join')}}</li>
                    <!-- <li class="towmenuitem" @click.stop="tiaozhuan('https://view.zzseeing.com/#/panoview/50228')">VR</li> -->
                    <!-- <li class="towmenuitem" @click.stop="nav('laboratory')" v-if="$i18n.locale == 'zh'">试验室</li> -->
                    <li class="towmenuitem" @click.stop="nav('develop')">{{$t('layout.develop')}}</li>
                    <li class="towmenuitem" @click.stop="tiaozhuan('http://smartgen.com.cn//yun/data/smartgen.mp4')">{{$t('layout.xuanchuan')}}</li>
                    <!-- <li class="towmenuitem" @click.stop="tiaozhuan('https://a37kh2j10.wasee.com/wt/a37kh2j10')">{{$t('layout.quanjing')}}</li> -->
                  </ul>
                </div>
              </div>
            </li>
            <li class="menuitem" @click="nav('contactus')">{{$t('layout.contact')}}</li>
            <li class="menuitem" @click="nav('investor')" v-if="$i18n.locale == 'zh'">投资者关系</li>
            <!-- <li class="menuitem" @click="tiaozhuan('https://smartgen.jd.com')" v-if="$i18n.locale == 'zh'">{{$t('layout.sumaitong')}}</li> -->
            <!-- <li class="menuitem" @click="tiaozhuan('https://shop113335048.taobao.com/')">{{$t('layout.store')}}</li> -->
            <li class="menuitem" @click="tiaozhuan('http://www.smartgencloudplus.com')">{{$t('layout.cloudplatform')}}</li>
          </ul>
        </div>
        <div v-if="$i18n.locale == 'zh'" style="text-align:center;color: #D10B17;font-weight: bold;line-height: 70px;">股票代码：301361</div>
        <div class="rightmenu">
          <div class="search">
            <i class="el-icon-search" v-if="!search" @click="searchbtn"></i>
            <i class="el-icon-close" v-if="search" @click="searchbtn"></i>
          </div>
          <div>
            <el-dropdown style="height:50px">
              <span class="el-dropdown-link">
                <div class="lang">
                  {{$t('lang')}}
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="tiaozhuan('http://www.smartgen.com.cn/')">中文</el-dropdown-item>
                <el-dropdown-item @click.native="tiaozhuan('http://www.smartgen.cn/')">English</el-dropdown-item>
                <el-dropdown-item @click.native="tiaozhuan('http://www.smartgen.hk/')">繁体</el-dropdown-item>
                <!-- <el-dropdown-item @click.native="tiaozhuan('http://smartgen.pt/')">Portugal</el-dropdown-item>
                <el-dropdown-item @click.native="tiaozhuan('http://smartgen.es/')">España</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div v-if="!userinfo" class="regbtn" @click="nav('reg')">
            {{$t('layout.register')}}
          </div>
          <div v-if="!userinfo" class="logbtn" @click="nav('login')">
            {{$t('layout.login')}}
          </div>
          <div v-if="userinfo" style="font-size:18px">
            <el-dropdown style="height:50px">
              <span class="el-dropdown-link" style="cursor:pointer">
                {{$t('layout.userName')}}:{{userinfo.names}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="nav('personal')">个人中心</el-dropdown-item>
                <el-dropdown-item @click.native="logout">{{$t('layout.exit')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden-sm-and-up">
      <div class="iphonenav">
        <div class="menu" @click="drawer = true">
          <i class="el-icon-s-fold"></i>
        </div>
        <div class="imagelog">
          <el-image :src="require('@/assets/logo2'+($i18n.locale == 'en' ? '_en' : '')+'.png')" class="image"></el-image>
        </div>
        <div style="margin-left:15%;color:#fff;font-size:14px;line-height: 25px;">
          <span @click="handleCommand()">{{$t('iphone')}}</span>
        </div>
      </div>
      <el-drawer title="" :with-header="false" :visible.sync="drawer" :direction="direction" size="70%">
        <el-menu default-active="2" class="el-menu-vertical-demo">
          <el-menu-item index="1" @click="nav('index')">
            <i class="el-icon-menu"></i>
            <span slot="title">{{$t('layout.home')}}</span>
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>{{$t('layout.productcenter')}}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item :index="'2-'+index" v-for="(item,index) in chanpin" :key="index" @click="nav('productinfo?class_id='+item.cat_id)">{{item.cat_name}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>{{$t('layout.solution')}}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1" v-for="(item,index) in fangan" :key="index" @click="nav('schemeinfo')">{{item.class_name}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="4">
            <i class="el-icon-setting"></i>
            <span slot="title" @click="nav('applicationcase')">{{$t('layout.application')}}</span>
          </el-menu-item>
          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>{{$t('layout.technical')}}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="5-1" @click="nav('datadownload')">{{$t('layout.download')}}</el-menu-item>
              <el-menu-item index="5-2" @click="nav('productcatalogue')">{{$t('layout.model')}}</el-menu-item>
              <el-menu-item index="5-3" @click="nav('ecucode')">{{$t('layout.ECUfaultcode')}}</el-menu-item>
              <el-menu-item index="5-4" @click="nav('faqs')">{{$t('layout.FAQ')}}</el-menu-item>
              <el-menu-item index="5-5" @click="nav('feedback')">{{$t('layout.Productfeedback')}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>{{$t('layout.about')}}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="6-1" @click="nav('aboutus')">{{$t('layout.wenhua')}}</el-menu-item>
              <el-menu-item index="6-1" @click="nav('news')">{{$t('layout.news')}}</el-menu-item>
              <el-menu-item index="6-2" @click="nav('honor')">{{$t('layout.honor')}}</el-menu-item>
              <el-menu-item index="6-3" @click="nav('trademark')">{{$t('layout.logo')}}</el-menu-item>
              <el-menu-item index="6-4" @click="nav('hr')" v-if="$i18n.locale != 'en'">{{$t('layout.join')}}</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="7" @click="nav('contactus')">
            <i class="el-icon-setting"></i>
            <span slot="title">{{$t('layout.contact')}}</span>
          </el-menu-item>
          <!-- <el-menu-item index="8" @click="tiaozhuan('https://shop113335048.taobao.com/')">
                        <i class="el-icon-setting"></i>
                        <span slot="title" >{{$t('layout.store')}}</span>
                    </el-menu-item> -->
          <el-menu-item index="9" @click="tiaozhuan('http://www.smartgencloudplus.com')">
            <i class="el-icon-setting"></i>
            <span slot="title">{{$t('layout.cloudplatform')}}</span>
          </el-menu-item>
        </el-menu>
      </el-drawer>
    </div>
    <router-view></router-view>
    <div class="bottom">
      <div class="hidden-sm-and-up">
        <el-collapse>
          <el-collapse-item :title="$t('layout.about')" name="1">
            <div class="items" @click="nav('news')">{{$t('layout.news')}}</div>
            <div class="items" @click="nav('aboutus')">{{$t('layout.about')}}</div>
            <div class="items" @click="nav('honor')">{{$t('layout.honor')}}</div>
            <div class="items" @click="nav('trademark')">{{$t('layout.logo')}}</div>
            <div class="items" @click="nav('hr')" v-if="$i18n.locale != 'en'">{{$t('layout.join')}}</div>
          </el-collapse-item>
          <el-collapse-item :title="$t('layout.productClassification')" name="2">
            <div class="items" v-for="(item,index) in chanpin" :key="index" @click="nav('productinfo?class_id='+item.cat_id)">{{item.cat_name}}</div>
          </el-collapse-item>
          <el-collapse-item :title="$t('layout.solution')" name="3">
            <div class="items" v-for="(item,index) in fanganList" :key="index" @click="nav('scheme/'+item.scheme_id)">{{item.scheme_title}}</div>
          </el-collapse-item>
          <el-collapse-item :title="$t('layout.technical')" name="4">
            <div class="items" @click="nav('datadownload')">{{$t('layout.download')}}</div>
            <div class="items" @click="nav('productcatalogue')">{{$t('layout.model')}}</div>
            <div class="items" @click="nav('ecucode')">{{$t('layout.ECUfaultcode')}}</div>
            <div class="items" @click="nav('faqs')">{{$t('layout.FAQ')}}</div>
            <div class="items" @click="nav('feedback')">{{$t('layout.Productfeedback')}}</div>
          </el-collapse-item>
          <el-collapse-item :title="$t('layout.followUs')" name="5">
            <div style="display:flex">
              <div class="iphoneimage">
                <el-image class="images" :src="require('@/assets/index/cnweixin.png')"></el-image>
                <span>{{$t('layout.chineseWeChatServiceNumber')}}</span>
              </div>
              <div class="iphoneimage">
                <el-image class="images" :src="require('@/assets/index/enweixin.png')"></el-image>
                <span>{{$t('layout.englishWeChatServiceNumber')}}</span>
              </div>
              <div class="iphoneimage">
                <el-image class="images" :src="require('@/assets/index/chanpin.png')"></el-image>
                <span>{{$t('layout.productAPP')}}</span>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div style="padding-left:5%;font-size:14px;color: rgba(255, 255, 255, 0.5);">
          <div class="topclass">
            <i class="el-icon-phone-outline"></i><span class="infotitle">+86-371-67988888/67981888</span>
          </div>
          <div class="topclass">
            <i class="el-icon-phone-outline"></i><span class="infotitle">+86-371-67992952</span>
          </div>
          <div class="topclass">
            <i class="el-icon-phone-outline"></i><span class="infotitle">sales@smartgen.cn</span>
          </div>
          <!-- <div class="topclass">
            <i class="el-icon-phone-outline"></i><span class="infotitle">{{$t('layout.sumaitong')}}</span>
          </div> -->
          <div class="topclass">
            <i class="el-icon-phone-outline"></i><span class="infotitle">{{$t('layout.address')}}</span>
          </div>
          <div class="topclass">
            <i class="el-icon-phone-outline"></i><span class="infotitle">Woodward</span>
          </div>
        </div>
      </div>
      <div class="hidden-xs-only bottomtop">
        <div class="content">
          <el-row>
            <el-col :span="2" :xs="8" :offset="1">
              <div class="titlle">
                {{$t('layout.about')}}
              </div>
              <div class="xia">
                <div class="item" @click="nav('news')">{{$t('layout.news')}}</div>
                <div class="item" @click="nav('aboutus')">{{$t('layout.about')}}</div>
                <div class="item" @click="nav('honor')">{{$t('layout.honor')}}</div>
                <div class="item" @click="nav('trademark')">{{$t('layout.logo')}}</div>
                <div class="item" @click="nav('hr')" v-if="$i18n.locale == 'zh'">{{$t('layout.join')}}</div>
                <div class="item" @click="nav('develop')">{{$t('layout.develop')}}</div>
                <div class="item" @click="tiaozhuan('http://smartgen.com.cn//yun/data/smartgen.mp4')">{{$t('layout.xuanchuan')}}</div>
                <!-- <div class="item" @click="tiaozhuan('https://a37kh2j10.wasee.com/wt/a37kh2j10')">{{$t('layout.quanjing')}}</div> -->
              </div>
            </el-col>
            <el-col :span="3" :xs="8">
              <div class="titlle">
                {{$t('layout.productClassification')}}
              </div>
              <div class="xia">
                <div class="item" v-for="(item,index) in chanpin" :key="index" @click="nav('productinfo?class_id='+item.cat_id)">{{item.cat_name}}</div>
              </div>
            </el-col>
            <el-col :span="3" :xs="8">
              <div class="titlle">
                {{$t('layout.solution')}}
              </div>
              <div class="xia">
                <div class="item" v-for="(item,index) in fangan" :key="index" @click="nav('schemeinfo?class_id='+item.id)">{{item.class_name}}</div>
              </div>
            </el-col>
            <el-col :span="3" :xs="8">
              <div class="titlle">
                {{$t('layout.technical')}}
              </div>
              <div class="xia">
                <div class="item" @click="nav('datadownload')">{{$t('layout.download')}}</div>
                <div class="item" @click="nav('productcatalogue')">{{$t('layout.model')}}</div>
                <div class="item" @click="nav('ecucode')">{{$t('layout.ECUfaultcode')}}</div>
                <div class="item" @click="nav('faqs')">{{$t('layout.FAQ')}}</div>
                <div class="item" @click="nav('feedback')">{{$t('layout.Productfeedback')}}</div>
              </div>
            </el-col>
            <el-col :span="3" :xs="8">
              <div class="titlle">
                {{$t('layout.WeMedia')}}
              </div>
              <div class="xia">
                <div class="item" @click="tiaozhuan($t('layout.weibourl'))">
                  <el-image v-if="$i18n.locale == 'en'" :src="require('@/assets/icon/YouTube.png')" style="vertical-align:middle;"></el-image>{{$t('layout.weibo')}}
                </div>
                <div class="item" @click="tiaozhuan($t('layout.bokeurl'))">
                  <el-image v-if="$i18n.locale == 'en'" :src="require('@/assets/icon/Facebook.png')" style="vertical-align:middle;"></el-image>{{$t('layout.boke')}}
                </div>
                <div class="item" @click="tiaozhuan($t('layout.tencenturl'))" v-if="$t('layout.tencenturl') != ''">
                  <el-image v-if="$i18n.locale == 'en'" :src="require('@/assets/icon/Linkedin.png')" style="vertical-align:middle;"></el-image>{{$t('layout.tencentVideo')}}
                </div>
                <div class="item" @click="tiaozhuan($t('layout.Youkuurl'))" v-if="$t('layout.Youkuurl') != ''">
                  <el-image v-if="$i18n.locale == 'en'" :src="require('@/assets/icon/Twitter.png')" style="vertical-align:middle;"></el-image>{{$t('layout.Youku')}}
                </div>
                <div class="item" @click="tiaozhuan($t('layout.Tudouurl'))" v-if="$t('layout.Tudouurl') != ''">
                  <el-image v-if="$i18n.locale == 'en'" :src="require('@/assets/icon/Pinterest.png')" style="vertical-align:middle;"></el-image>{{$t('layout.Tudou')}}
                </div>
                <div class="item" @click="tiaozhuan($t('layout.DocInurl'))">
                  <el-image v-if="$i18n.locale == 'en'" :src="require('@/assets/icon/VK.png')" style="vertical-align:middle;"></el-image>{{$t('layout.DocIn')}}
                </div>
                <div class="item" @click="tiaozhuan($t('layout.baiduurl'))" v-if="$i18n.locale == 'zh'">{{$t('layout.baidu')}}</div>
                <div class="item" @click="tiaozhuan('https://space.bilibili.com/1649135175')" v-if="$i18n.locale == 'zh'">众智哔哩哔哩</div>
              </div>
            </el-col>
            <el-col :span="9" :xs="16">
              <div class="titlle">
                {{$t('layout.followUs')}}
              </div>
              <div class="xias">
                <div class="xiaimage">
                  <el-image class="image" :src="require('@/assets/index/cnweixin.png')"></el-image>
                  <span>{{$t('layout.chineseWeChatServiceNumber')}}</span>
                </div>
                <div class="xiaimage">
                  <el-image class="image" :src="require('@/assets/index/enweixin.png')"></el-image>
                  <span>{{$t('layout.englishWeChatServiceNumber')}}</span>
                </div>
                <div class="xiaimage">
                  <el-image class="image" :src="require('@/assets/index/chanpin.png')"></el-image>
                  <span>{{$t('layout.productAPP')}}</span>
                </div>
              </div>
              <div class="info">
                <el-row>
                  <el-col :span="24" v-if="$i18n.locale != 'en'">
                    <i class="el-icon-user"></i><span class="infotitle">{{$t('theFreeCall')}}：400-0318-908</span>
                  </el-col>
                  <el-col :span="14">
                    <i class="el-icon-phone-outline"></i><span class="infotitle"> {{$t('guanyu.pbx')}}：+86-371-67988888/67981888</span>
                  </el-col>
                  <el-col :span="10">
                    <i class="el-icon-printer"></i><span class="infotitle"> {{$t('guanyu.fax')}}：+86-371-67992952</span>
                  </el-col>
                 
                  <el-col :span="14">
                    <i class="el-icon-message"></i><span class="infotitle">sales@smartgen.cn  info@smartgen.cn</span>
                  </el-col>
                  <!-- <el-col :span="10" v-if="$i18n.locale == 'zh'">
                    <i class="el-icon-goods"></i><span class="infotitle" style="cursor:pointer;" @click="tiaozhuan('https://smartgen.jd.com')">{{$t('layout.sumaitong')}}</span>
                  </el-col> -->
                  <el-col :span="10" >
                    <i class="el-icon-user"></i><span class="infotitle" style="cursor:pointer;" @click="tiaozhuan('http://www.woodward.com/')">Woodward</span>
                  </el-col>
                  <el-col :span="10" v-if="$i18n.locale == 'en'">
                    <i class="el-icon-user"></i>
                    <span class="infotitle" style="cursor:pointer;" @click="tiaozhuan('https://www.genexpress1.com')">AC&C</span>
                  </el-col>
                  <el-col :span="14">
                    <i class="el-icon-location-information"></i><span class="infotitle">{{$t('layout.address')}}</span>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="bottoms">
        <span>Copyright© Since 1998 SmartGen <br /><a href="http://beian.miit.gov.cn/" target="view_window">豫ICP备11033962号-2</a><br /><a href="https://tongji.baidu.com/main/homepage/10000466672/homepage/index" target="view_window">统计工具</a></span>
      </div>
      <div class="liuyanban" v-show="lyshow && this.$i18n.locale == 'en'">
        <div class="lytitle">
          <div>CONTACT US</div>
          <div style="font-size:16px;cursor: pointer;" @click="guanbily"><i class="el-icon-minus"></i></div>
        </div>
        <div class="lycontent">
          <el-form :model="lyForm" ref="lyForm" :rules="rules" label-width="45px">
            <el-form-item prop="name">
              <template>
                <span slot="label">
                  <span><i class="el-icon-user"></i></span>
                </span>
              </template>
              <el-input v-model="lyForm.name" placeholder="NAME:"></el-input>
            </el-form-item>
            <el-form-item prop="youxiang">
              <template>
                <span slot="label">
                  <span><i class="el-icon-message"></i></span>
                </span>
              </template>
              <el-input v-model="lyForm.youxiang" placeholder="E-matil:"></el-input>
            </el-form-item>
            <el-form-item prop="country">
              <template>
                <span slot="label">
                  <span><i class="el-icon-location-outline"></i></span>
                </span>
              </template>
              <el-input v-model="lyForm.country" placeholder="Country:"></el-input>
            </el-form-item>
            <el-form-item prop="company">
              <template>
                <span slot="label">
                  <span><i class="el-icon-office-building"></i></span>
                </span>
              </template>
              <el-input v-model="lyForm.company" placeholder="Company:"></el-input>
            </el-form-item>
            <el-form-item prop="jianyi">
              <template>
                <span slot="label">
                  <span><i class="el-icon-chat-square"></i></span>
                </span>
              </template>
              <el-input v-model="lyForm.jianyi" type="textarea" :rows="4" placeholder="Please leave massage to us."></el-input>
            </el-form-item>
            <el-form-item>
              <template>
                <span slot="label">
                  <span></span>
                </span>
              </template>
              <div class="lybtn" @click="lysubmit">SEND INQUIRY</div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div v-if="cookieshow && $i18n.locale == 'en'" class="cookiediv" style="position:fixed;bottom:0;left:0;height: 60px;width:100%;background: #000000;opacity: 0.7;z-index:99">
      <div style="color: #FFFFFF;display:flex;justify-content:center;font-size: 16px;font-weight: 400;padding-top:18px;line-height:30px;">
        <div>
          This site uses cookies. By continuing to browse the site you are agreeing to our use of cookies. <span style="text-decoration: underline;cursor:pointer;" @click="nav('policy')">Read our privacy policy</span>
        </div>
        <div style="width: 64px;height: 30px;border: 1px solid #DCDCDC;text-align:center;margin-left:15px;cursor:pointer;" @click="deny()">
          DENY
        </div>
        <div style="width: 116px;height: 30px;background: rgba(255, 255, 255, 0.9);text-align:center;color: #0052D9;margin-left:15px;cursor:pointer;" @click="accept()">
          ACCEPT ALL
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/display.css';
import { index, schemeList } from "@/api/api";
import { Message } from "element-ui";
import { productscollectAdd } from "@/api/api";
export default {
  data() {
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      if (!value) {
        return callback(new Error('email not null'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('Please enter the correct mailbox format'))
        }
      }, 100)
    }
    return {
      twocontent: '',
      drawer: false,
      direction: 'ltr',
      search: false,
      input2: '',
      active: '',
      chanpin: [],
      fangan: [],
      fanganList: [],
      userinfo: '',
      iconlist: {
        whatsapp: require('../assets/index/whatsapp.png'),
        email: require('../assets/index/email.png'),
        tel: require('../assets/index/tel.png'),
        top: require('../assets/index/top.png'),
        fk: require('../assets/index/fk.png'),
      },
      cindex: -1,
      clist: [],
      class_id: 0,
      twonavshow: false,
      lyForm: {},
      lyshow: true,
      rules: {
        name: [
          { required: true, message: 'Please fill in your name', trigger: 'blur' }
        ],
        youxiang: [
          { required: true, validator: checkEmail, trigger: 'blur' }
        ],
        country: [
          { required: true, message: 'Please fill in your country', trigger: 'blur' }
        ],
        jianyi: [
          { required: true, message: 'Please fill in your suggestion', trigger: 'blur' }
        ]
      },
      cookieshow: true
    }
  },
  watch: {
    $route: {
      handler(val, oldval) {
        let active = sessionStorage.getItem('active')
        this.active = active
        let userinfo = sessionStorage.getItem('userinfo')
        if (userinfo != undefined) {
          this.userinfo = JSON.parse(userinfo)
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  created() {
    let cookiediv = this.getCookie('cookiediv')
    if (cookiediv != '') {
      this.cookieshow = false
    }
    let active = sessionStorage.getItem('active')
    this.active = active
    let userinfo = sessionStorage.getItem('userinfo')
    if (userinfo != undefined && userinfo != null) {
      this.userinfo = JSON.parse(userinfo)
      this.userinfo.names = this.userinfo.name.substring(0, 5)
    }
    this.getList()
    this.getfangan()
  },
  methods: {
    async getList() {
      const { data: res } = await index()
      res.chanpin.forEach((item) => {
        if (item.children != undefined) {
          if (item.children.length > 5) {
            item.children.splice(5)
          }
          item.children.forEach((items) => {
            items.image = process.env.VUE_APP_BASE_API + items.ori_img
          })
        }
      })
      this.chanpin = res.chanpin
      this.fangan = res.fangan
    },
    async getfangan() {
      let p = {
        class_id: '',
        page: 1,
        limit: 10
      }
      const { data: res } = await schemeList()
      this.fanganList = res.list
    },
    selectStyle(content) {
      this.twocontent = content
    },
    nav(item) {
      this.$router.push('/' + item);
      this.drawer = false
    },
    searchbtn() {
      this.search = !this.search
    },
    searchpage() {
      this.$router.push('/search?key=' + this.input2);
    },
    logout() {
      this.userinfo = ''
      sessionStorage.removeItem('userinfo')
      this.$router.push('/index')
    },
    qieyuyan(lang) {
      if (lang == 'zh') {
        this.$i18n.locale = 'zh'
      } else if (lang == 'en') {
        this.$i18n.locale = 'en'
      } else if (lang == 'hk') {
        this.$i18n.locale = 'hk'
      }
      sessionStorage.setItem('lang', lang)
    },
    tiaozhuan(href) {
      console.log('11111');
      window.open(href, '_blank');
    },
    top() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    mouseover(item, index, cat_id) {
      this.class_id = cat_id
      this.cindex = index
      this.twonavshow = true
      item.children.forEach((item, index) => {
        item.image = process.env.VUE_APP_BASE_API + item.ori_img
      })
      this.clist = item.children
    },
    mouseleave() {
      this.cindex = -1
      this.twonavshow = false
    },
    guanbily() {
      this.lyshow = false
    },
    lysubmit() {
      this.$refs["lyForm"].validate(valid => {
        if (valid) {
          productscollectAdd(this.lyForm).then(response => {
            Message({
              message: 'success',
              type: 'success'
            })
            this.lyForm = {}
          })
        }
      });
    },
    liuyanopen() {
      this.lyshow = true
    },
    setCookie(name, value, day) {
      var date = new Date();
      date.setDate(date.getDate() + day);
      //domain=.cnblogs.com;写入cookie到主域 子域名都可用    path=/表示本站全部路径都可使用
      document.cookie = name + '=' + value + ';expires=' + date + ';path=/';
    },
    getCookie(name) {
      var reg = RegExp(name + '=([^;]+)');
      var arr = document.cookie.match(reg);
      if (arr) {
        return arr[1];
      } else {
        return '';
      }
    },
    deny() {
      this.setCookie('cookiediv', '1', 1)
      this.cookieshow = false
    },
    accept() {
      this.setCookie('cookiediv', '1', 1000)
      this.cookieshow = false
    },
    handleCommand(){
      window.location.href = process.env.VUE_APP_HOST+"/mobile?lang="+this.$i18n.locale
    }
  }
}
</script>
<style lang="scss" scoped>
.rightfloat {
  position: fixed;
  right: 0px;
  top: 40%;
  z-index: 100;
  .righticon {
    cursor: pointer;
    top: 0px;
    right: 0px;
    position: absolute;
    overflow-x: hidden;
    width: 45px;
    height: 45px;
    background: #c7c7c7;
    transition: width 1s;
    -moz-transition: width 1s; /* Firefox 4 */
    -webkit-transition: width 1s; /* Safari and Chrome */
    -o-transition: width 1s; /* Opera */
    .citem {
      width: 45px;
      display: flex;
      height: 45px;
      .rcontent {
        width: 300px;
        position: absolute;
        left: 50px;
        top: 5px;
        .rtitle {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
        }
        .rtitle2 {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          margin-top: 3px;
        }
      }
    }
  }
  .ding {
    cursor: pointer;
    top: 220px;
    right: 0px;
    position: absolute;
    .dicon {
      width: 45px;
      height: 45px;
      background-color: #c7c7c7;
      color: #fff;
      font-size: 30px;
      line-height: 60px;
      text-align: center;
    }
    .dicon:hover {
      background-color: #0052d9;
    }
  }
  .fk {
    top: 165px;
  }
  .righticon:hover {
    width: 200px;
    background-color: #0052d9;
  }
  .email {
    top: 55px;
  }
  .tel {
    top: 110px;
  }
}
.nav {
  height: 65px;
  background: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
  .content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-family: SourceHanSansCN-Regular;
    font-weight: 400;
    color: #333333;
    line-height: 56px;
    font-size: 14px;
    .logo {
      margin-top: 1%;
      width: 160px;
      cursor: pointer;
    }
    .menu {
      line-height: 40px;
      .menuitem {
        float: left;
        display: block;
        margin-right: 28px;
        cursor: pointer;
        font-family: SourceHanSansCN-Regular;
      }
      .menulist > li:hover {
        border-bottom: 1px solid #0052d9;
      }
      .twomenu {
        z-index: 10;
        position: absolute;
        background-color: #fff;
        width: 100%;
        top: 55px;
        left: 0px;
        display: none;
        padding-top: 1%;
        .twocontent {
          width: 60%;
          margin: 0 auto;
          display: flex;
          padding-top: 2%;
          .xilieitem {
            margin-right: 5%;
            .xilieimage {
              width: 183px;
            }
            .biaoti {
              font-family: SourceHanSansCN-Regular;
              font-weight: 400;
              font-size: 14px;
              width: 183px;
              text-align: center;
            }
          }
        }
        .page {
          padding-top: 20px;
          width: 60px;
          text-align: center;
          margin: 0 auto;
          font-size: 14px;
          color: #333333;
          line-height: 30px;
          border-bottom: 1px solid #d8d8d8;
          margin-bottom: 2%;
        }
      }
      .activit:hover .twomenu {
        display: block;
      }
      .act {
        border-bottom: 1px solid #0052d9;
      }
      .twomenulist {
        width: 80%;
        margin: 0 auto;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      .twomenulist > li:hover {
        color: #0052d9;
      }
      .towmenuitem {
        height: 45px;
        margin-right: 2%;
      }
      .towmenuitem:hover .twonav {
        display: block;
      }
      .twonav {
        background-color: #fff;
        color: black;
      }
    }
    .rightmenu {
      display: flex;
      justify-content: space-between;
      line-height: 75px;
      .search {
        width: 3vw;
        font-size: 16px;
        cursor: pointer;
      }
      .lang {
        margin-top: 25px;
        margin-right: 1.3vw;
        width: 30px;
        height: 25px;
        line-height: 25px;
        border: 1px solid #333333;
        text-align: center;
        cursor: pointer;
      }
      .regbtn {
        margin-right: 15px;
        cursor: pointer;
      }
      .logbtn {
        cursor: pointer;
      }
      .actbtn {
        color: #0052d9;
      }
    }
  }
}
.bottom {
  margin-top: 30px;
  background: #262626;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  .items {
    padding-left: 20px;
    margin-top: 2%;
  }
  .iphoneimage {
    width: 120px;
    height: 150px;
    margin-left: 1%;
    text-align: center;
  }
  .images {
    width: 120px;
    height: 120px;
  }
  .topclass {
    padding-top: 2%;
    margin-bottom: 10px;
  }
  .infotitle {
    margin-left: 2%;
  }
  .bottomtop {
    .content {
      width: 80%;
      margin: 0 auto;
      padding-top: 2%;
      .titlle {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 21px;
      }
      .xia {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        opacity: 0.7;
        margin-top: 20px;
        .item {
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
      .xias {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        margin-top: 20px;
        display: flex;
        .xiaimage {
          width: 124px;
          height: 154px;
          margin-right: 6%;
          text-align: center;
          .image {
            width: 124px;
            height: 124px;
            margin-bottom: 10px;
          }
        }
      }
      .info {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        opacity: 0.7;
        margin-top: 3%;
        .el-col {
          margin-top: 4%;
        }
      }
    }
  }
  .bottoms {
    padding-top: 7px;
    background: #0c0c0c;
    height: 80px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
  }
}
.iphonenav {
  padding-top: 2%;
  display: flex;
  height: 40px;
  background: #262626;
  .menu {
    color: #ffffff;
    font-size: 28px;
  }
  .imagelog {
    margin-left: 25%;
    width: 150px;
    margin-top: 2px;
  }
}
.liuyanban {
  position: fixed;
  bottom: 5px;
  right: 50px;
  z-index: 100;
  width: 300px;
  height: 480px;
  background: #f5f5f5;
  .lytitle {
    height: 40px;
    line-height: 40px;
    background: #0052d9;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
  }
  .lycontent {
    padding-left: 10px;
    padding-right: 20px;
    margin-top: 20px;
  }
  .lybtn {
    cursor: pointer;
    width: 100%;
    height: 40px;
    background: #0052d9;
    border: 1px solid #0052d9;
    color: #fff;
    text-align: center;
  }
}
@media screen and (max-width: 1280px) {
  .nav {
    .content {
      width: 90%;
      .logo {
        margin-top: 1%;
        width: 140px;
        height: 38px;
        cursor: pointer;
      }
      .rightmenu {
        margin-right: 110px;
      }
    }
  }
}
@media screen and (max-width: 1540px) {
  .nav {
    .content {
      width: 100%;
      .logo {
        margin-top: 1%;
        width: 140px;
        height: 38px;
        cursor: pointer;
      }
      .menu {
        .menuitem {
          margin-right: 20px;
        }
      }
      .rightmenu {
        margin-right: 10px;
      }
    }
  }
}
</style>
<style scoped>
.bottom >>> .el-collapse {
  border: none;
  background-color: #262626 !important;
}
.bottom >>> .el-collapse-item {
  background-color: #262626 !important;
}
.bottom >>> .el-collapse-item__header {
  padding-left: 5%;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  background-color: #262626 !important;
}
.bottom >>> .el-collapse-item__wrap {
  background-color: #262626 !important;
}
.bottom >>> .el-collapse-item__content {
  color: rgba(255, 255, 255, 0.5);
}
</style>